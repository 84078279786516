<template>
  <v-container id="companies">
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <h2 class="align-self-end page-title">
          {{ $t(`settings.title.companies`) }}
        </h2>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        class="d-flex flex-column flex-sm-row justify-end"
      >
        <BaseSearch id="search" :label="`search`" @search="setSearch" />
        <v-btn
          id="addCompany"
          class="ml-sm-5"
          elevation="1"
          color="blue-grey lighten-5"
          min-width="265px"
          height="39px"
          @click="$emit('mode', ['company/add', true])"
          v-if="isAdmin"
        >
          {{ $t("action.addCompany") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-wrap mt-2 card-wrap">
        <v-card
          class="mb-4"
          elevation="1"
          v-for="company in companies"
          :key="company.id"
        >
          <v-card-title class="d-flex justify-space-between align-start">
            <div class="company-name d-inline-flex flex-column">
              <span :title="company.name">{{ company.name }}</span>
              <span class="text-body-2">
                {{ company.okpo }}
              </span>
            </div>
            <!--<v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>-->
            <v-menu
              bottom
              left
              transition="slide-y-transition"
              v-if="company.status !== 'FIRED'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  id="openAction"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :ripple="false"
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <span>{{ $t("action.openAction") }}</span>
                  </v-tooltip>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  id="edit"
                  @click="$emit('event', ['editCompany', company])"
                >
                  <v-list-item-title class="d-flex align-center">
                    <v-icon medium class="mr-2" color="blue darken-3">
                      mdi-briefcase-edit
                    </v-icon>
                    {{ $t("action.edit") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <template v-if="company.storeCount && company.employeeCount">
            <v-card-text class="d-flex justify-space-between">
              <span>{{ $t("settings.company.employeeCount") }}</span>
              <span class="mr-3">{{ company.employeeCount }}</span>
            </v-card-text>
            <v-card-text class="d-flex justify-space-between pt-0">
              <span>{{ $t("settings.company.storeCount") }}</span>
              <span class="mr-3">{{ company.storeCount }}</span>
            </v-card-text>
            <v-card-text class="d-flex justify-space-between pt-0">
              <span>{{ $t("settings.company.cpOnWeek") }}</span>
              <span class="mr-3">{{ company.cpOnWeek }}</span>
            </v-card-text>
            <v-card-text class="d-flex justify-space-between pt-0 pb-2">
              <span>{{ $t("settings.company.cpCount") }}</span>
              <span class="mr-3">{{ company.cpCount }}</span>
            </v-card-text>
          </template>
          <template v-if="!company.storeCount || !company.employeeCount">
            <v-card-text
              class="d-flex flex-column justify-center company-empty"
            >
              <v-icon color="orange accent-2" large>mdi-alert-circle</v-icon>
              <span class="mt-2 text-center">
                {{ $t("settings.company.companyEmpty") }}
              </span>
            </v-card-text>
          </template>
          <v-card-actions class="px-4 py-3">
            <v-btn
              color="blue-grey lighten-5"
              elevation="0"
              width="100%"
              @click="openCompany(company)"
            >
              {{ $t("action.open") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseSearch from "@/components/BaseSearch";
import { mapGetters } from "vuex";

export default {
  name: "SettingsCompanies",
  props: {
    options: Object
  },
  data() {
    return {
      search: ""
    };
  },
  components: {
    BaseSearch
  },
  computed: {
    ...mapGetters(["getUser"]),
    companies() {
      if (this.search) {
        return this.options.companies.filter(el => {
          let search = this.search.toLowerCase();
          let name = el.name.toLowerCase();
          let okpo = el.okpo.toLowerCase();

          if (name.includes(search) || okpo.includes(search)) {
            return el;
          }
        });
      }
      return this.options.companies;
    },
    isAdmin() {
      return this.getUser?.userGrants.includes("ADMINISTRATOR");
    }
  },
  methods: {
    setSearch(data) {
      this.search = data;
    },
    openCompany(company) {
      this.$emit("event", ["openCompany", company]);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped lang="scss">
#search {
  max-width: 400px;
  @media (max-width: 599.9px) {
    max-width: 100%;
    width: 100%;
  }
}
#addCompany {
  @media (max-width: 599.9px) {
    margin-top: 20px;
    width: 100%;
  }
}
.card-wrap {
  justify-content: space-between;
  @media (min-width: 1040px) {
    justify-content: start;
  }
}
.v-card {
  width: 305px;
  margin-right: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  @media (max-width: 1200px) {
    width: 305px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  @media (max-width: 1040px) {
    width: 48.7%;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
}
.v-card__title {
  font-size: 15px;
  font-weight: bold;
  .company-name {
    max-width: 80%;
    span:first-child {
      display: inline-block;
      line-height: 1.7;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        cursor: pointer;
      }
    }
    @media (max-width: 768px) {
      max-width: 80%;
    }
  }
}
.company-empty {
  width: calc(100% - 32px);
  margin: auto;
  min-height: 144px;
  background-color: #f2f2f2;
  border-radius: 4px;
}
</style>
